import React from 'react';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DOMPurify from 'isomorphic-dompurify';
import Image from 'next/image';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useStorefrontData from 'src/hooks/useStorefrontData';

import PageTitle from 'src/components/PageTitle';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 540,
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#6e8dd3',
      backgroundImage:
        'linear-gradient(to top, #ffffff 0%, rgba(255, 255, 255, 0) 100%)'
    }
  },
  blueTitle: {
    display: 'inline-block',
    color: '#0e4798',
    [theme.breakpoints.down('md')]: {
      color: '#ffffff'
    },
    background: 'linear-gradient(to right,#142241 0%,#0e4798 100%)',
    '-webkit-background-clip': 'text'
  },
  goldTitle: {
    display: 'inline-block',
    position: 'relative',
    color: theme.palette.secondary.dark,
    background: 'linear-gradient(to right,#e9b109 0%,#9b7501 100%)',
    textShadow: 'none',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  },
  subTitle: {
    fontSize: '1.75rem',
    textShadow: '1px 2px 5px rgba(0,0,0,0.55)',
    fontWeight: 400,
    letterSpacing: '5px',
    textTransform: 'uppercase',
    maxWidth: 1000,
    marginBottom: 40,
    paddingLeft: 15,
    paddingRight: 15,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      lineHeight: '1.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '1.25rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
      lineHeight: '0.75rem',
      marginBottom: 10
    }
  },
  h1: {
    paddingTop: 10
  },
  skeleton: {
    paddingTop: 17
  },
  relative: {
    position: 'relative',
    maxWidth: 1000,
    margin: '0 auto',
    whiteSpace: 'break-spaces'
  }
}));

const Banner = ({children}) => {
  const classes = useStyles();
  const {page} = useStorefrontData();
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <div className={clsx(classes.root, classes.webp)}>
      {!isDownSm && (
        <Image
          priority
          layout='fill'
          objectFit='cover'
          src='/images/main_background.jpg'
        />
      )}
      {page ? (
        <div className={classes.relative}>
          <PageTitle page={page} />
          <Typography
            className={clsx(classes.subTitle)}
            component='h4'
            align='center'
            color='textSecondary'
            dangerouslySetInnerHTML={{
              __html: page && DOMPurify.sanitize(page.subtitle)
            }}
          />
        </div>
      ) : (
        <div align='center' className={classes.skeleton}>
          <Typography variant='h3' component='div'>
            <Skeleton variant='text' width={400} />
          </Typography>
          <Typography variant='h6' component='div'>
            <Skeleton variant='text' className={classes.subTitle} width={400} />
          </Typography>
        </div>
      )}
      {children}
    </div>
  );
};

Banner.propTypes = {
  styled: PropTypes.bool,
  children: PropTypes.node
};

Banner.defaultProps = {
  styled: false
};

export default Banner;
